import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import _ from 'lodash';

import './Layout.less';

import Header from './Header';
import Navigation from './Navigation';

class LayoutComponent extends Component {
  state = {
    collapsed: true,
  };

  sliderToggle = status => {
    this.setState(state => ({
      collapsed: _.isBoolean(status) ? status : !state.collapsed,
    }));
  };

  render() {
    return (
      <Layout className="layout">
        <Header toggle={this.sliderToggle} />
        <Layout>
          <Navigation collapsed={this.state.collapsed} toggle={this.sliderToggle} />
          <Layout.Content className="content">{this.props.children}</Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
