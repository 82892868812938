import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DotLoader } from 'react-spinners';

class PageSpinner extends Component {
  render() {
    const { size, color, loading } = this.props;
    return <DotLoader sizeUnit="px" size={size} color={color} loading={loading} />;
  }
}

PageSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  loading: PropTypes.bool,
};

PageSpinner.defaultProps = {
  size: 100,
  color: '#acc437',
  loading: true,
};

export default PageSpinner;
