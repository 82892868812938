const { NODE_ENV, STAGING } = process.env;

const env = STAGING ? 'staging' : NODE_ENV || 'development';

const config = {
  development: {
    frontendUri: 'http://localhost',
    auth: 'http://localhost:3000',
    portal: 'http://localhost:3001',
    tt: 'http://localhost:3002',
    bi: 'http://localhost:3003',
  },
  staging: {
    frontendUri: 'http://ec2-3-144-98-168.us-east-2.compute.amazonaws.com',
    auth: 'http://ec2-3-144-98-168.us-east-2.compute.amazonaws.com:3000',
    portal: 'http://ec2-3-144-98-168.us-east-2.compute.amazonaws.com:3001',
    tt: 'http://ec2-3-144-98-168.us-east-2.compute.amazonaws.com:3002',
    bi: 'http://ec2-3-144-98-168.us-east-2.compute.amazonaws.com:3003',
  },
  production: {
    frontendUri: 'https://bboapps.com',
    auth: 'https://account.bboapps.com',
    portal: 'https://portal.bboapps.com',
    tt: 'https://tt.bboapps.com',
    bi: 'https://bi.bboapps.com',
  },
};

console.info('ENV:', env);

export default config[env];
