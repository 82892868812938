import * as actionTypes from '../constants/modulesActionTypes';

export const loadModulesListStart = () => ({
  type: actionTypes.FETCH_MODULES_START,
});

export const loadModulesListSuccess = modulesList => ({
  type: actionTypes.FETCH_MODULES_SUCCESS,
  payload: modulesList,
});

export const loadModulesListFailure = err => ({
  type: actionTypes.FETCH_MODULES_FAILURE,
  payload: err,
});

export const clearModulesListStore = () => ({
  type: actionTypes.MODULES_CLEAR_STORE,
});
