import {
  SET_CURRENT_STATE,
  FETCH_STATES_LIST,
  GET_SINGLE_STATE,
  SET_STATES_PARAMS,
  CLEAR_SINGLE_STATE_STORE,
  GET_SINGLE_STATE_SET_IS_STARTED,
  GET_SINGLE_STATE_SET_IS_ERROR,
  GET_SINGLE_STATE_SET_IS_LOAD,
} from '../constants/statesActionsTypes';

const initialState = {
  statesList: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10,
    sort: 'created_at,desc',
    search: '',
    scope: '',
  },
  isLoading: false,
  singleState: {},
  isStarted: false,
  isLoad: false,
  isError: false,
};

function stateReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_STATE:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_STATES_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_STATE:
      return {
        ...state,
        singleState: action.data,
      };
    case SET_STATES_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case CLEAR_SINGLE_STATE_STORE: {
      return {
        ...state,
        singleState: {},
        isStarted: false,
        isLoad: false,
        isError: false,
      };
    }
    case GET_SINGLE_STATE_SET_IS_STARTED:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
      };
    case GET_SINGLE_STATE_SET_IS_ERROR:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
      };
    case GET_SINGLE_STATE_SET_IS_LOAD:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: false,
      };

    default:
      return state;
  }
}

export default stateReducer;
