export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY';
export const FETCH_COUNTRIES_LIST = 'FETCH_COUNTRIES_LIST';
export const GET_SINGLE_COUNTRY = 'GET_SINGLE_COUNTRY';
export const SET_COUNTRIES_PARAMS = 'SET_COUNTRIES_PARAMS';

export const CLEAR_COUNTRIES_PARAMS = 'CLEAR_COUNTRIES_PARAMS';
export const CLEAR_COUNTRIES_STORE = 'CLEAR_COUNTRIES_STORE';
export const CLEAR_SINGLE_COUNTRY_STORE = 'CLEAR_SINGLE_COUNTRY_STORE';

export const GET_SINGLE_COUNTRY_SET_IS_STARTED = 'GET_SINGLE_COUNTRY_SET_IS_STARTED';
export const GET_SINGLE_COUNTRY_SET_IS_ERROR = 'GET_SINGLE_COUNTRY_SET_IS_ERROR';
export const GET_SINGLE_COUNTRY_SET_IS_LOAD = 'GET_SINGLE_COUNTRY_SET_IS_LOAD';
