import * as actions from '../actions/modules.action';

import { Licenses } from '../../DAO_hocs/licensesDAO_HOC';

export const loadModulesList = () => async dispatch => {
  await dispatch(actions.loadModulesListStart());
  try {
    const response = await Licenses.getModules({
      params: {
        page: 1,
        per_page: 10,
        scope: 'withLicenseTypes',
      },
    });
    const { modules } = response.data.data;
    await dispatch(actions.loadModulesListSuccess(modules));
  } catch (e) {
    await dispatch(actions.loadModulesListFailure(e.message));
  }
};

export const clearModulesStore = () => async dispatch => {
  await dispatch(actions.clearModulesListStore());
};
