/*
 * Refresh Request Singleton Store
 * */

function RefreshRequest() {
  let refreshRequest = null;
  return {
    getRefreshRequest: () => refreshRequest,
    setRefreshRequest: value => {
      refreshRequest = value;
      return refreshRequest;
    },
  };
}

export default RefreshRequest();
