export const FETCH_TENANTS_LIST = 'FETCH_TENANTS_LIST';
export const GET_SINGLE_TENANT = 'GET_SINGLE_TENANT';
export const SET_TENANT_PARAMS = 'SET_TENANT_PARAMS';
export const CREATE_TENANT = 'CREATE_TENANT';
export const UPDATE_TENANT = 'UPDATE_TENANT';
export const FETCH_MINIFIED_TENANTS_LIST = 'FETCH_MINIFIED_TENANTS_LIST';

export const CLEAR_TENANT_STORE = 'CLEAR_TENANT_STORE';

export const GET_SINGLE_TENANT_SET_IS_STARTED = 'GET_SINGLE_TENANT_SET_IS_STARTED';
export const GET_SINGLE_TENANT_SET_IS_ERROR = 'GET_SINGLE_TENANT_SET_IS_ERROR';
export const GET_SINGLE_TENANT_SET_IS_LOAD = 'GET_SINGLE_TENANT_SET_IS_LOAD';
