import * as actions from '../actions/account.action';

import { Users } from '../../DAO_hocs/usersDAO_HOC';
import { Sts } from '../../DAO_hocs/stsDAO_HOC';

import { loadRolesList } from './roles.controller';
import { loadModulesList } from './modules.controller';

const afterAccountLoadActions = [loadRolesList, loadModulesList];

export const loadAccount = () => async dispatch => {
  await dispatch(actions.loadAccountStart());
  try {
    const response = await Users.whoAmI({
      params: {
        scope: ['withPermission', 'withAvatar', 'withLicense'],
      },
    });
    const account = response.data.data;
    await dispatch(actions.loadAccountSuccess(account));
    const promises = afterAccountLoadActions.map(
      actionFunc => new Promise(resolve => resolve(dispatch(actionFunc()))),
    );
    Promise.all(promises);
  } catch (e) {
    await dispatch(actions.loadAccountFailure(e.message));
  }
};

export const clearAccount = () => async dispatch => {
  await dispatch(actions.clearAccountStore());
};

export const logout = () => async dispatch => {
  try {
    await Sts.appLogout();
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(clearAccount());
  }
};

export const goToAccount = () => async dispatch => {
  try {
    dispatch(loadAccount({}));
    await Sts.appAccount();
  } catch (e) {
    console.error(e);
  }
};
