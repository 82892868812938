export const SET_CURRENT_STATE = 'SET_CURRENT_STATE';
export const FETCH_STATES_LIST = 'FETCH_STATES_LIST';
export const GET_SINGLE_STATE = 'GET_SINGLE_STATE';
export const SET_STATES_PARAMS = 'SET_STATES_PARAMS';

export const CLEAR_STATES_PARAMS = 'CLEAR_STATES_PARAMS';
export const CLEAR_STATES_STORE = 'CLEAR_STATES_STORE';
export const CLEAR_SINGLE_STATE_STORE = 'CLEAR_SINGLE_STATE_STORE';

export const GET_SINGLE_STATE_SET_IS_STARTED = 'GET_SINGLE_STATE_SET_IS_STARTED';
export const GET_SINGLE_STATE_SET_IS_ERROR = 'GET_SINGLE_STATE_SET_IS_ERROR';
export const GET_SINGLE_STATE_SET_IS_LOAD = 'GET_SINGLE_STATE_SET_IS_LOAD';
