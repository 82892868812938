import {
  FETCH_TENANTS_LIST,
  GET_SINGLE_TENANT,
  SET_TENANT_PARAMS,
  FETCH_MINIFIED_TENANTS_LIST,
  GET_SINGLE_TENANT_SET_IS_STARTED,
  GET_SINGLE_TENANT_SET_IS_ERROR,
  GET_SINGLE_TENANT_SET_IS_LOAD,
} from '../constants/tenantActionsTypes';

const initialState = {
  tenants: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10, //eslint-disable-line
    sort: 'created_at,desc',
    search: '',
    scope: ['withLicense', 'withMainLocation', 'withUserId', 'withAvatar'],
  },
  isLoading: false,
  singleTenant: null,
  isStarted: false,
  isLoad: false,
  isError: false,
};

function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANTS_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_TENANT:
      return {
        ...state,
        singleTenant: action.data,
      };
    case SET_TENANT_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case FETCH_MINIFIED_TENANTS_LIST:
      return {
        ...state,
        tenantsList: action.tenantsList,
      };
    case GET_SINGLE_TENANT_SET_IS_STARTED:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
      };
    case GET_SINGLE_TENANT_SET_IS_ERROR:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
      };
    case GET_SINGLE_TENANT_SET_IS_LOAD:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: false,
      };
    default:
      return state;
  }
}

export default tenantReducer;
