const baseAPI = '/api';

export default {
  host: '',
  baseAPI,
  ms: {
    licenses: `${baseAPI}/licenses-ms`,
    users: `${baseAPI}/users-ms`,
    tenants: `${baseAPI}/tenants-ms`,
    sts: `${baseAPI}/sts`,
    bi: `${baseAPI}/bi-ms`,
    triage: `${baseAPI}/triage-ms`,
    locations: `${baseAPI}/locations-ms`,
    files: `${baseAPI}/files-ms`,
    audit: `${baseAPI}/audit-ms`,
  },
};
