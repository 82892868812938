// const constantedPermissions = [
//   'permissions',
//   'roles',
//   'users',
//   'clients',
//   'licenses',
//   'companies',
//   'clientsProfiles',
//   'marketingProfiles',
//   'campaigns',
//   'locations',
//   'productLines',
//   'marketingServices',
//   'conversions',
//   'webTraffics',
//   'adVariables',
//   'marketingPlans'];

export const PERMISSIONS = {
  READ: 'permissions:read',
  CREATE: 'permissions:create',
  UPDATE: 'permissions:update',
  DELETE: 'permissions:remove',
};

export const ROLES = {
  READ: 'roles:read',
  CREATE: 'roles:create',
  UPDATE: 'roles:update',
  DELETE: 'roles:remove',
};

export const USERS = {
  READ: 'users:read',
  CREATE: 'users:create',
  UPDATE: 'users:update',
  DELETE: 'users:remove',
};

export const CLIENTS = {
  READ: 'clients:read',
  CREATE: 'clients:create',
  UPDATE: 'clients:update',
  DELETE: 'clients:remove',
};

export const LICENSES = {
  READ: 'licenses:read',
  CREATE: 'licenses:create',
  UPDATE: 'licenses:update',
  DELETE: 'licenses:remove',
};

export const STATES = {
  READ: 'states:read',
  CREATE: 'states:create',
  UPDATE: 'states:update',
  DELETE: 'states:remove',
};

export const PREDEFINED_ROLES = [1, 2, 3, 4, 5, 6, 7, 8];
