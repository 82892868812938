import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  DashboardIcon,
  //  MenuTriggerIcon,
  NotificationIcon,
  OrganisationsIcon,
  ProfilesIcon,
  PuzzleIcon,
  ProfileBadgeIcon,
  WorldIcon,
} from '../Icons';
import NavModuleList from '../NavModuleList';

import routes from '../../pages';

@withRouter
@connect(store => ({ account: store.account.account }))
class MenuComponent extends Component {
  static getIconByRouteTitle(title) {
    switch (title) {
      case 'Dashboard':
        return DashboardIcon;
      case 'Customers':
        return OrganisationsIcon;
      case 'Users':
        return ProfilesIcon;
      case 'Notifications':
        return NotificationIcon;
      case 'Modules':
        return PuzzleIcon;
      case 'Roles':
        return ProfileBadgeIcon;
      case 'States':
        return WorldIcon;
      default:
        return null;
    }
  }
  render() {
    const { t } = this.props;
    const { role_id } = this.props.account;
    return (
      <Menu
        mode="inline"
        theme="light"
        className="nav"
        selectedKeys={[this.props.location.pathname]}
      >
        {/* <Icon className="icon trigger" component={MenuTriggerIcon} onClick={this.props.toggle} /> */}
        {routes.map(route =>
          route.hidden ? null : (
            <Menu.Item key={route.path}>
              <Icon
                className="icon nav-icon"
                component={MenuComponent.getIconByRouteTitle(route.title)}
              />
              <span>{t(route.title)}</span>
              <Link
                key={`${route.path}pg`}
                to={route.path}
                onClick={() => this.props.toggle(true)}
              />
            </Menu.Item>
          ),
        )}
        {role_id === 1 ? (
          <Menu.Item key="/states">
            <Icon className="icon nav-icon" component={WorldIcon} />
            <span>{t('States')}</span>
            <Link to="/states" onClick={() => this.props.toggle(true)} />
          </Menu.Item>
        ) : null}
        <NavModuleList currentModule="portal" />
      </Menu>
    );
  }
}

MenuComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  toggle: PropTypes.func.isRequired,
  account: PropTypes.shape({
    role_id: PropTypes.number.isRequired,
  }),
  t: PropTypes.func,
};

MenuComponent.defaultProps = {
  location: null,
  account: null,
  t: null,
};

export default withTranslation()(MenuComponent);
