import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from '../public/locales/en/translation.json';
import de from '../public/locales/de/translation.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    languages: ['en-US', 'de-DE'],
    fallbackLng: 'en',
    debug: true,

    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupCookie: 'lang',
      caches: ['cookie'],
      checkWhitelist: true,
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
