import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

import './PageSpinner.less';
import i18n from '../../i18n';

class PageSpinner extends Component {
  render() {
    return (
      <div id="page-spinner" className="spinner-wrapper">
        <Spinner {...this.props.spinnerProps} />
        <span className="spinner-title">{this.props.msg}</span>
      </div>
    );
  }
}

PageSpinner.propTypes = {
  spinnerProps: PropTypes.objectOf(PropTypes.string),
  msg: PropTypes.string,
};

PageSpinner.defaultProps = {
  spinnerProps: {},
  msg: i18n.t('Loading...'),
};

export default PageSpinner;
