import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './reducers/user.reducer';
import tenantReducer from './reducers/tenant.reducer';
import metaReducer from './reducers/meta.reducer';
import accountReducer from './reducers/account.reducer';
import rolesReducer from './reducers/roles.reducer';
import filesReducer from './reducers/files.reducer';
import modulesReducer from './reducers/modules.reducer';
import statesReducer from './reducers/states.reducer';
import countriesReducer from './reducers/countries.reducer';

const reducer = combineReducers({
  meta: metaReducer,
  account: accountReducer, // current user profile
  user: userReducer,
  tenant: tenantReducer,
  roles: rolesReducer,
  files: filesReducer,
  modules: modulesReducer,
  states: statesReducer,
  countries: countriesReducer,
});

export default createStore(reducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));
