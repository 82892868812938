import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tag, Icon } from 'antd';
import { withTranslation } from 'react-i18next';

import frontendApps from '../../config/frontendApps';

import './Forbidden.less';

@connect(store => ({
  errMessage: store.meta.errMessage,
}))
class Forbidden extends Component {
  render() {
    const { errMessage, t } = this.props;
    return (
      <div className={classnames('forbidden-root', this.props.rootClassName)}>
        <div className="left-side">
          <div className="shapes">
            <div className="shape-1" />
            <div className="shape-2" />
            <div className="shape-3" />
            <div className="shape-4" />
            <div className="shape-5" />
          </div>
          <div className="content">
            <div className="main-part">
              <div className="label">
                <span>ADMIN</span>
                <span>PORTAL</span>
              </div>
              <div className="message">
                <div className="title">{t('Oops!')}</div>
                <div className="text">
                  {errMessage || t(`You don't have permission to access this page`)}
                </div>
                <div className="error">
                  <span>{t('Error code')}: </span>
                  <span>403</span>
                </div>
              </div>
              <Tag className="forbidden-button">
                <a href={frontendApps.auth}>{t('Go Back')}</a>
                <Icon type="right" />
              </Tag>
            </div>
          </div>
        </div>
        <div className="right-side" />
      </div>
    );
  }
}

Forbidden.propTypes = {
  rootClassName: PropTypes.string,
  errMessage: PropTypes.string,
  t: PropTypes.func,
};

Forbidden.defaultProps = {
  rootClassName: '',
  errMessage: null,
  t: null,
};

export default withTranslation()(Forbidden);
