import {
  SET_CURRENT_USER,
  FETCH_USERS_LIST,
  GET_SINGLE_USER,
  SET_USERS_PARAMS,
  CLEAR_SINGLE_USER_STORE,
  GET_SINGLE_USER_SET_IS_STARTED,
  GET_SINGLE_USER_SET_IS_ERROR,
  GET_SINGLE_USER_SET_IS_LOAD,
} from '../constants/userActionsTypes';

const initialState = {
  users: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10,
    sort: 'created_at,desc',
    search: '',
    scope: ['withRole', 'withTenant'],
  },
  isLoading: false,
  singleUser: {},
  isStarted: false,
  isLoad: false,
  isError: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_USERS_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.data,
      };
    case SET_USERS_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case CLEAR_SINGLE_USER_STORE: {
      return {
        ...state,
        singleUser: {},
        isStarted: false,
        isLoad: false,
        isError: false,
      };
    }
    case GET_SINGLE_USER_SET_IS_STARTED:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
      };
    case GET_SINGLE_USER_SET_IS_ERROR:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
      };
    case GET_SINGLE_USER_SET_IS_LOAD:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: false,
      };

    default:
      return state;
  }
}

export default userReducer;
