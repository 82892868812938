import { notification } from 'antd';
import _ from 'lodash';

import {
  APP_SHOW_LOADER,
  APP_HIDE_LOADER,
  OPEN_PROFILE_MODAL,
  CLOSE_PROFILE_MODAL,
  APP_SUCCESS_TOAST,
  APP_ERROR_TOAST,
  OPEN_GO_PREMIUM_MODAL,
  CLOSE_GO_PREMIUM_MODAL,
  APP_REFRESH_TOAST,
  APP_FORBIDDEN,
  APP_INTERNAL_SERVER_ERROR,
} from '../actions/meta.action';

const initialState = {
  isMobileTablet: false,
  url: '',
  isPageLoading: false,
  query: {},
  isProfileModalOpen: false,
  toast: {
    type: '', // ['success', 'error']
    isShown: false,
    // showSuccessToast: false,
    // showErrorToast: false,
    message: '',
  },
  needToShowGoPremiumModal: false,
  isGoPremiumModalOpen: false,
  appForbidden: false,
  errMessage: null,
  internalServerError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_SHOW_LOADER:
      return {
        ...state,
        isPageLoading: true,
      };
    case APP_HIDE_LOADER:
      return {
        ...state,
        isPageLoading: false,
      };
    case OPEN_PROFILE_MODAL:
      return {
        ...state,
        isProfileModalOpen: true,
      };
    case CLOSE_PROFILE_MODAL:
      return {
        ...state,
        isProfileModalOpen: false,
        // toast: {
        //   showSuccessToast: false,
        //   showErrorToast: false,
        //   message: ''
        // },
      };
    case APP_SUCCESS_TOAST:
      notification.success({
        message: 'Success',
        description: action.message,
      });
      return {
        ...state,
        toast: {
          isShown: true,
          type: 'success',
          message: action.message,
        },
      };
    case APP_ERROR_TOAST: {
      const message = _.get(action.message, 'response.data.message', action.message);
      const displayMessage = _.isString(message) ? message : 'Request Failed';
      notification.error({
        message: 'Failure',
        description: displayMessage,
      });
      return {
        ...state,
        toast: {
          isShown: true,
          type: 'error',
          message: displayMessage,
        },
      };
    }
    case APP_REFRESH_TOAST:
      return {
        ...state,
        toast: {
          isShown: false,
          type: '',
          message: '',
        },
      };
    case APP_FORBIDDEN:
      return {
        ...state,
        appForbidden: true,
        errMessage: _.get(action, 'error.message', null),
      };
    case APP_INTERNAL_SERVER_ERROR:
      return {
        ...state,
        internalServerError: true,
      };
    case OPEN_GO_PREMIUM_MODAL:
      return {
        ...state,
        isGoPremiumModalOpen: true,
      };
    case CLOSE_GO_PREMIUM_MODAL:
      return {
        ...state,
        isGoPremiumModalOpen: false,
        toast: {
          showSuccessToast: false,
          showErrorToast: false,
          message: '',
        },
      };
    default:
      return state;
  }
};
