import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

export const getIcon = title => (
  <svg width="16px" height="16px">
    <use xlinkHref={`#${title}`} />
  </svg>
);

export const MenuTriggerIcon = () => getIcon('icon-menu-1');

export const DashboardIcon = () => getIcon('icon-menu-2');

export const OrganisationsIcon = () => getIcon('icon-organisations');

export const OrganisationsAddIcon = () => getIcon('icon-organisations-add');

export const ProfileIcon = () => getIcon('icon-profile');

export const ProfileAddIcon = () => getIcon('icon-profile-add');

export const ProfilesIcon = () => getIcon('icon-profiles');

export const ProfilesAddIcon = () => getIcon('icon-profiles-add');

export const NotificationIcon = () => getIcon('icon-notification');

export const PuzzleIcon = () => getIcon('icon-puzzle');

export const SearchIcon = () => getIcon('icon-search');

export const SearchIconShape = () => getIcon('icon-search-shape');

export const VerticalDotsIcon = () => getIcon('icon-vertical-dots');

export const VerticalArrow = () => getIcon('icon-rounded-rectangle-right');

export const HorizontalArrow = () => getIcon('icon-rounded-rectangle-bottom');

export const CrossIcon = () => getIcon('icon-cross');

export const FilterIcon = () => getIcon('icon-filter');

export const SettingsIcon = () => getIcon('icon-settings');

export const WarnIcon = () => getIcon('icon-warn');

export const CheckIcon = () => getIcon('icon-check');

export const ArrowShackLeft = () => getIcon('icon-arrow-shack-left');

export const StateAddIcon = () => getIcon('icon-state-add');

export const TTModuleIcon = props => (
  <span {...props} className={classnames('module-icon tt-module-icon', props.className || '')}>
    WP
  </span>
);

TTModuleIcon.propTypes = {
  className: propTypes.string,
};

TTModuleIcon.defaultProps = {
  className: '',
};

export const BIModuleIcon = props => (
  <span {...props} className={classnames('module-icon bi-module-icon', props.className || '')}>
    BI
  </span>
);

BIModuleIcon.propTypes = {
  className: propTypes.string,
};

BIModuleIcon.defaultProps = {
  className: '',
};

export const PGModuleIcon = props => (
  <span {...props} className={classnames('module-icon pg-module-icon', props.className || '')}>
    PG
  </span>
);

PGModuleIcon.propTypes = {
  className: propTypes.string,
};

PGModuleIcon.defaultProps = {
  className: '',
};

export const PictureIcon = () => getIcon('icon-picture');

export const CameraIcon = () => getIcon('icon-camera');

export const PhoneIcon = () => getIcon('icon-phone');

export const LocationIcon = () => getIcon('icon-location-2');

export const WorldIcon = () => getIcon('icon-world-1');

export const ProfileCloseIcon = () => getIcon('icon-profile-close-1');

export const DataLineIcon = () => getIcon('icon-data-line');

export const TimeIcon = () => getIcon('icon-time-1');

export const MessageIcon = () => getIcon('icon-message-1');

export const ProfileBadgeIcon = () => getIcon('icon-profile-badge-2');

export const BaggageIcon = () => getIcon('icon-baggage');

export const InjuryIcon = () => getIcon('icon-injury');
