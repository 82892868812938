import axios from 'axios';
import _ from 'lodash';

import refreshRequestStore from './refreshRequest';

import { Sts } from './stsDAO_HOC';

export default class Api {
  constructor(options = {}) {
    this.client =
      options.client ||
      axios.create({
        baseURL: options.baseUrl,
      });
    this.baseURL = options.baseUrl;
    this.stsClient = Sts;

    this.client.interceptors.response.use(
      r => r,
      async error => {
        if (!error.response || error.response.status !== 401 || error.config.retry) {
          // reassign default error message to response message ( if exist )
          const message = _.get(error, 'response.data.message', error.message);
          const reassignError = Object.assign(error, { message });
          return Promise.reject(reassignError);
        }
        const refreshRequest = refreshRequestStore.getRefreshRequest();
        if (!refreshRequest) {
          // set Promise to refresh request
          refreshRequestStore.setRefreshRequest(this.stsClient.refreshToken());
        }
        // wait for refresh
        await refreshRequestStore.getRefreshRequest();
        // create new config
        const newRequest = {
          ...error.config,
          baseURL: '', // prevent url concatenation during retry process
          retry: true, // retry trigger, prevent infinite retry loop
        };
        // retry failed request
        return this.client(newRequest);
      },
    );

    this.appLogout = () => this.stsClient.appLogout();

    this.public = {
      get: ({ url = '', ...rest }) => this.client.get(url, rest),

      post: ({ url = '', ...rest }) => this.client.post(url, rest),

      put: ({ url = '', ...rest }) => this.client.put(url, rest),

      patch: ({ url = '', ...rest }) => this.client.patch(url, rest),

      delete: ({ url = '', ...rest }) => this.client.delete(url, rest),

      defaultPost: this.client.post,
    };
  }
}
