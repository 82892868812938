import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Icon, Spin, Drawer, Card, Radio, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import { loadAccount } from '../../redux/controllers/account.controller';
import stsDAO from '../../DAO_hocs/stsDAO_HOC';

import { SettingsIcon } from '../Icons';
import Avatar from '../Avatar/Avatar';
import './BackToOrigin.less';

@connect(
  store => ({
    account: store.account.account,
  }),
  {
    loadAccount,
  },
)
@stsDAO
@withRouter
class BackToOriginUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerVisible: false,
      isRadioChecked: true, // true - stay on this page, false => push to '/users'
      isLoading: false,
    };
  }

  showDrawerToggle = () => {
    this.setState(state => ({
      isDrawerVisible: !state.isDrawerVisible,
    }));
  };

  radioToggle = () => {
    this.setState(state => ({
      isRadioChecked: !state.isRadioChecked,
    }));
  };

  loginAsOriginHandler = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      await this.props.stsDAO.backToOrigin();
      if (!this.state.isRadioChecked) {
        this.props.history.push('/users');
      }
      await this.props.loadAccount();
    } catch (e) {
      console.error(e);
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { account, t } = this.props;
    if (_.isEmpty(account)) return <Spin spinning />;
    if (_.isEmpty(account.origin_user)) return null;
    const { id, first_name, last_name, role } = account.origin_user;
    return (
      <div className="back-to-origin" id="back-to-origin">
        <Icon
          className="icon icon-hover"
          onClick={this.showDrawerToggle}
          component={SettingsIcon}
        />
        <Drawer
          title={t('Origin Account')}
          onClose={this.showDrawerToggle}
          closable={!this.state.isLoading}
          visible={this.state.isDrawerVisible}
          getContainer={() => document.getElementById('back-to-origin')}
        >
          <Card>
            <Card.Meta
              avatar={<Avatar id={id} username={`${first_name} ${last_name}`} />}
              title={`${first_name} ${last_name}`}
              description={<p className="text-capitalize">{role.title}</p>}
            />
            <Radio
              disabled={this.state.isLoading}
              onChange={this.radioToggle}
              checked={this.state.isRadioChecked}
            >
              {t('Stay on this page')}
            </Radio>
            <Radio
              disabled={this.state.isLoading}
              onChange={this.radioToggle}
              checked={!this.state.isRadioChecked}
            >
              {t('Back to Users List')}
            </Radio>
            <Button
              loading={this.state.isLoading}
              htmlType="button"
              onClick={this.loginAsOriginHandler}
              icon="login"
            >
              {t('Login As Origin')}
            </Button>
          </Card>
        </Drawer>
      </div>
    );
  }
}

BackToOriginUser.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    origin_user: {
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      role: {
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      },
    },
  }),
  loadAccount: PropTypes.func,
  stsDAO: PropTypes.shape({
    backToOrigin: PropTypes.func.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  t: PropTypes.func,
};

BackToOriginUser.defaultProps = {
  account: null,
  loadAccount: null,
  stsDAO: null,
  history: null,
  t: null,
};

export default withTranslation()(BackToOriginUser);
