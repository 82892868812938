import { Layout, Icon, Dropdown, Menu, Button } from 'antd';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '../Avatar/Avatar';
import { MenuTriggerIcon } from '../Icons';
import BackToOriginUser from '../BackToOriginUser';

import { logout, goToAccount } from '../../redux/controllers/account.controller';

import './Header.less';

@connect(
  store => ({
    account: store.account.account,
  }),
  {
    logout,
    goToAccount,
  },
)
class HeaderComponent extends Component {
  getDropdownMenu = () => (
    <Menu>
      <Menu.Item>
        <Button htmlType="button" className="btn-default" onClick={this.props.goToAccount}>
          {this.props.t('My Info')}
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button htmlType="button" className="btn-default" onClick={this.props.logout}>
          {this.props.t('Log Out')}
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          htmlType="button"
          className={`btn-default ${
            get(this.props, 'i18n.language') === 'en-US' ? 'inactive' : ''
          }`}
          onClick={() => invoke(this.props, 'i18n.changeLanguage', 'en-US')}
        >
          English
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          htmlType="button"
          className={`btn-default ${
            get(this.props, 'i18n.language') === 'de-DE' ? 'inactive' : ''
          }`}
          onClick={() => invoke(this.props, 'i18n.changeLanguage', 'de-DE')}
        >
          Deutsch
        </Button>
      </Menu.Item>
    </Menu>
  );

  render() {
    if (isEmpty(this.props.account)) return null;
    // const { t } = this.props;
    // const { first_name, last_name, id, files } = this.props.account;
    const isAvatar = !isEmpty(get(this.props, 'account.files'));
    const fullName = `${get(this.props, 'account.first_name')} ${get(
      this.props,
      'account.last_name',
    )}`;
    const id = get(this.props, 'account.id');
    return (
      <Layout.Header className="header">
        <div className="header-first-section">
          <Icon className="icon trigger" component={MenuTriggerIcon} onClick={this.props.toggle} />
          <Link className="company-logo-link" to="/">
            <span className="company-logo">{invoke(this.props, 't', 'Admin Portal')}</span>
          </Link>
        </div>
        <div className="header-second-section ant-row-flex ant-row-flex-middle ant-row-flex-center">
          <Dropdown
            overlay={this.getDropdownMenu}
            getPopupContainer={e =>
              e.parentNode
            } /* tie to parentNode, default tie to document.body */
            trigger={['click']}
            placement="bottomCenter"
            overlayClassName="header-dropdown"
          >
            {/* <Icon component={HorizontalArrow} className="icon icon-hover" /> */}
            <div className="hover-pointer">
              {isAvatar ? (
                <Avatar username={fullName} id={id} />
              ) : (
                <Avatar username={fullName} id={id} src="" useBasePath={false} />
              )}
              <span className="header__user-name">{fullName}</span>
            </div>
          </Dropdown>
        </div>
        <div className="header-last-section ant-row-flex ant-row-flex-middle ant-row-flex-center">
          <BackToOriginUser />
        </div>
      </Layout.Header>
    );
  }
}

HeaderComponent.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  toggle: PropTypes.func.isRequired,
  logout: PropTypes.func,
  goToAccount: PropTypes.func,
  t: PropTypes.func,
};

HeaderComponent.defaultProps = {
  account: null,
  logout: null,
  goToAccount: null,
  t: null,
};

export default withTranslation()(HeaderComponent);
