import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin, Menu } from 'antd';

import { BIModuleIcon, PGModuleIcon, TTModuleIcon } from '../Icons';

import frontendApps from '../../config/frontendApps';
import licensesDAO from '../../DAO_hocs/licensesDAO_HOC';

@connect(
  store => ({
    account: store.account.account,
  }),
  null,
)
@licensesDAO
class NavModuleList extends Component {
  static getModuleLink(moduleId) {
    switch (moduleId) {
      case 1:
        return frontendApps.tt;
      case 2:
        return frontendApps.bi;
      default:
        return frontendApps.portal;
    }
  }

  static getModuleIcon(moduleId) {
    switch (moduleId) {
      case 1:
        return <TTModuleIcon />;
      case 2:
        return <BIModuleIcon />;
      default:
        return <PGModuleIcon />;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      moduleList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.loadLicenseModules();
  }

  loadLicenseModules = async () => {
    this.setState({
      loading: true,
    });
    try {
      const { tenant, role_id } = this.props.account;

      if (role_id === 1) {
        // if admin - show all modules
        const { data } = await this.props.licensesDAO.getModules();
        this.setState({
          moduleList: data.data.modules.map(module => ({ id: module.id, title: module.title })),
        });
      } else {
        // else - show modules that user has license
        this.setState({
          moduleList: tenant && tenant.licenses.map(license => license.module),
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { moduleList, loading } = this.state;
    if (loading) return <Spin />;
    if (!moduleList) return null;

    return (
      <Menu selectedKeys={[]} defaultSelectedKeys={[]}>
        <Menu.Divider className="ant-menu-item-divider" />
        {!!moduleList.length &&
          moduleList.map(({ id, title }) => (
            <Menu.Item title={`${title} Module`} className="ant-menu-item nav-link" key={id}>
              <a href={NavModuleList.getModuleLink(id)} target="_blank" rel="noopener noreferrer">
                {NavModuleList.getModuleIcon(id)}
              </a>
            </Menu.Item>
          ))}
      </Menu>
    );
  }
}

NavModuleList.propTypes = {
  account: PropTypes.shape({
    tenant: PropTypes.shape({
      licenses: PropTypes.shape({
        module_id: PropTypes.number,
      }),
    }),
    role_id: PropTypes.number,
  }),
  licensesDAO: PropTypes.objectOf(PropTypes.func),
};

NavModuleList.defaultProps = {
  account: {},
  licensesDAO: null,
};

export default NavModuleList;
