/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import createDAO from './DAO';
import api from '../config/api';

class LicensesDAO extends createDAO {
  constructor() {
    super({ baseUrl: api.ms.licenses });
    const parent = this.public;

    this.public = {
      getModules: ({ ...options }) => parent.get({ url: '/modules', ...options }),

      getModuleById: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.get({ url: `/modules/${id}`, ...options });
      },

      createModule: ({ ...options }) => parent.post({ url: '/modules', ...options }),

      updateModule: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.put({ url: `/modules/${id}`, ...options });
      },

      getLicensesWithModules: ({ ...options }) =>
        parent.get({ url: '/licenses/modules', ...options }),

      deleteModule: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.delete({ url: `/modules/${id}`, ...options });
      },

      getLicenses: ({ ...options }) => parent.get({ url: '/licenses', ...options }),

      getLicenseById: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.get({ url: `/licenses/${id}`, ...options });
      },

      getLicensesByTenantId: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('Id is required'));
        return parent.get({ url: `/licenses/by-tenant/${id}`, ...options });
      },

      createLicense: ({ ...options }) => parent.post({ url: '/licenses', ...options }),

      updateLicense: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.put({ url: `/licenses/${id}`, ...options });
      },

      deleteLicense: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.delete({ url: `/licenses/${id}`, ...options });
      },

      activateLicense: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.get({ url: `/licenses/${id}/activate`, ...options });
      },

      getPrices: ({ ...options }) => parent.get({ url: '/prices', ...options }),

      createPrice: ({ ...options }) => parent.post({ url: '/prices', ...options }),

      getPriceById: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.get({ url: `/prices/${id}`, ...options });
      },

      updatePrice: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.put({ url: `/prices/${id}`, ...options });
      },

      deletePrice: ({ id, ...options }) => {
        if (!id) return Promise.reject('Id is required');
        return parent.delete({ url: `/prices/${id}`, ...options });
      },
    };
  }
}

const DAO = new LicensesDAO();

export const Licenses = DAO.public;

export default WrappedComponent =>
  class LicensesDaoHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} licensesDAO={Licenses} />;
    }
  };
