import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Layout, Drawer } from 'antd';

import MenuComponent from './Menu';

@withRouter
class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout.Sider
          className="sider hidden-sm"
          collapsed={this.props.collapsed}
          collapsible
          trigger={null}
          theme="light"
        >
          <MenuComponent toggle={this.props.toggle} />
        </Layout.Sider>
        <Drawer
          closable
          visible={!this.props.collapsed}
          placement="left"
          onClose={this.props.toggle}
          className="sider layout-navbar-drawer visible-sm"
        >
          <MenuComponent toggle={this.props.toggle} />
        </Drawer>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Navigation;
