import {
  SET_CURRENT_COUNTRY,
  FETCH_COUNTRIES_LIST,
  GET_SINGLE_COUNTRY,
  SET_COUNTRIES_PARAMS,
  CLEAR_SINGLE_COUNTRY_STORE,
  GET_SINGLE_COUNTRY_SET_IS_STARTED,
  GET_SINGLE_COUNTRY_SET_IS_ERROR,
  GET_SINGLE_COUNTRY_SET_IS_LOAD,
} from '../constants/countriesActionsTypes';

const initialState = {
  countriesList: [],
  total: 0,
  params: {
    page: 1,
    per_page: 3,
    sort: 'created_at,desc',
    search: '',
    scope: '',
  },
  isLoading: false,
  singleCountry: {},
  isStarted: false,
  isLoad: false,
  isError: false,
};

function countryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_COUNTRIES_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_COUNTRY:
      return {
        ...state,
        singleCountry: action.data,
      };
    case SET_COUNTRIES_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case CLEAR_SINGLE_COUNTRY_STORE: {
      return {
        ...state,
        singleCountry: {},
        isStarted: false,
        isLoad: false,
        isError: false,
      };
    }
    case GET_SINGLE_COUNTRY_SET_IS_STARTED:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
      };
    case GET_SINGLE_COUNTRY_SET_IS_ERROR:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
      };
    case GET_SINGLE_COUNTRY_SET_IS_LOAD:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: false,
      };

    default:
      return state;
  }
}

export default countryReducer;
