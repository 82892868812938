/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import _ from 'lodash';

import axios from 'axios';
import api from '../config/api';
import frontendApps from '../config/frontendApps';
import refreshRequestStore from './refreshRequest';

class StsDAO {
  constructor() {
    this.client = axios.create({
      baseURL: api.ms.sts,
    });
    this.baseURL = api.ms.sts;
    this.notRetryUrls = ['/login', '/logout', '/logout-all', '/refresh'];
    this.client.interceptors.response.use(
      response => {
        const redirectUrl = response.headers['x-redirect-to'];
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
        return response;
      },
      async error => {
        const url = _.get(error, 'response.config.url', '').replace(this.baseURL, '');
        if (
          !url ||
          this.notRetryUrls.includes(url) ||
          !error.response ||
          error.response.status !== 401 ||
          error.config.retry
        )
          return Promise.reject(error);
        const refreshRequest = refreshRequestStore.getRefreshRequest();
        if (!refreshRequest) {
          // set Promise to refresh request
          refreshRequestStore.setRefreshRequest(this.public.refreshToken());
        }
        // wait for refresh
        await refreshRequestStore.getRefreshRequest();
        // create new config
        const newRequest = {
          ...error.config,
          baseURL: '', // prevent url concatenation during retry process
          retry: true, // retry trigger, prevent infinite retry loop
        };
        // retry failed request
        return this.client(newRequest);
      },
    );

    this.public = {
      logout: () => this.client.get('/logout'),

      appLogout: () =>
        this.public.logout().finally(() => {
          window.location.replace(`${frontendApps.auth}/login?redirect=${window.location.href}`);
        }),

      loginAsUser: (userId, options) => {
        // put redirect: 'no_redirect' if you don't want to redirect automatically
        if (!userId) return Promise.reject('userId is required');
        const redirectParam = options && options.redirect ? `?redirect=${options.redirect}` : '';

        return this.client.post(`/login/as-user${redirectParam}`, {
          user_id: userId,
        });
      },

      backToOrigin: () => this.client.get('/login/back-to-origin'),

      logoutAll: ({ ...options }) => this.client.get('/logout-all', { ...options }).then(r => r),

      appAccount: () => {
        window.location.replace(`${frontendApps.auth}/account`);
      },

      refresh: () => this.client.get('/refresh'),

      refreshToken: async () => {
        try {
          await this.public.refresh();
          refreshRequestStore.setRefreshRequest(null);
        } catch (e) {
          // logout from app if refresh request failed
          this.public.appLogout();
          console.error(e);
          throw e;
        }
      },
    };
  }
}

const DAO = new StsDAO();

export const Sts = DAO.public;

export default WrappedComponent =>
  class StsHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} stsDAO={Sts} />;
    }
  };
