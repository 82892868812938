import * as actionTypes from '../constants/rolesActionTypes';

export const loadRolesListStart = () => ({
  type: actionTypes.FETCH_ROLES_START,
});

export const loadRolesListSuccess = rolesList => ({
  type: actionTypes.FETCH_ROLES_SUCCESS,
  payload: rolesList,
});

export const loadRolesListFailure = err => ({
  type: actionTypes.FETCH_ROLES_FAILURE,
  payload: err,
});

export const clearRolesListStore = () => ({
  type: actionTypes.ROLES_CLEAR_STORE,
});
