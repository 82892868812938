export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const SET_USERS_PARAMS = 'SET_USERS_PARAMS';

export const CLEAR_USERS_PARAMS = 'CLEAR_USERS_PARAMS';
export const CLEAR_USERS_STORE = 'CLEAR_USERS_STORE';
export const CLEAR_SINGLE_USER_STORE = 'CLEAR_SINGLE_USER_STORE';

export const GET_SINGLE_USER_SET_IS_STARTED = 'GET_SINGLE_USER_SET_IS_STARTED';
export const GET_SINGLE_USER_SET_IS_ERROR = 'GET_SINGLE_USER_SET_IS_ERROR';
export const GET_SINGLE_USER_SET_IS_LOAD = 'GET_SINGLE_USER_SET_IS_LOAD';

export const LOGIN_AS_START = 'LOGIN_AS_START';
export const LOGIN_AS_SUCCESS = 'LOGIN_AS_SUCCESS';
export const LOGIN_AS_FAILURE = 'LOGIN_AS_FAILURE';
