import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import 'babel-polyfill';

import { loadAccount, clearAccount } from './redux/controllers/account.controller';
import { licenseValidation } from './redux/actions/meta.action';
import Layout from './components/Layout/Layout';
import PageSpinner from './components/Spinner/PageSpinner';
import routes from './pages';
import ServerErrorPage from './pages/ServerError/ServerError';
import ForbiddenPage from './pages/Forbidden/Forbidden';
import './theme.less';
import './App.less';

@connect(
  store => ({
    account: store.account.account,
    isLoad: store.account.isLoad,
    isError: store.account.isError,
    appForbidden: store.meta.appForbidden,
  }),
  {
    loadAccount,
    clearAccount,
    licenseValidation,
  },
)
class App extends Component {
  componentDidMount() {
    this.props.licenseValidation();
    this.props.loadAccount();
  }
  componentWillUnmount() {
    this.props.clearAccount();
  }

  render() {
    const { isLoad, isError, account, appForbidden, internalServerError } = this.props;
    if (!isLoad) {
      return <PageSpinner msg="Loading..." />;
    }
    if (appForbidden || (account.role_id !== 1 && account.role_id !== 2)) return <ForbiddenPage />;
    if (isError || internalServerError) return <ServerErrorPage />;
    return (
      <BrowserRouter>
        <Layout>
          <Suspense fallback={<PageSpinner />}>
            <Switch>
              {routes.map(route => (
                <Route
                  key={route.path}
                  staticContext={this.props.context} // eslint-disable-line
                  {...this.props}
                  {...route}
                />
              ))}
            </Switch>
          </Suspense>
        </Layout>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  loadAccount: propTypes.func,
  clearAccount: propTypes.func,
  licenseValidation: propTypes.func,
  isLoad: propTypes.bool,
  internalServerError: propTypes.bool,
  isError: propTypes.bool,
  account: propTypes.shape({
    role_id: propTypes.number.isRequired,
  }),
  appForbidden: propTypes.bool,
};

App.defaultProps = {
  loadAccount: null,
  clearAccount: null,
  isLoad: false,
  isError: false,
  internalServerError: false,
  account: null,
  appForbidden: false,
  licenseValidation: null,
};

export default App;
