import * as actionTypes from '../constants/accountActionsTypes';

export const loadAccountStart = () => ({
  type: actionTypes.FETCH_ACCOUNT_START,
});

export const loadAccountSuccess = account => ({
  type: actionTypes.FETCH_ACCOUNT_SUCCESS,
  payload: account,
});

export const loadAccountFailure = err => ({
  type: actionTypes.FETCH_ACCOUNT_FAILURE,
  payload: err,
});

export const clearAccountStore = () => ({
  type: actionTypes.ACCOUNT_CLEAR_STORE,
});
