import React from 'react';
import withPermission from '../helpers/withPermission';
import * as PERMISSIONS from '../config/permissions';

const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const Users = React.lazy(() => import('./Users/UsersContainer'));
const Clients = React.lazy(() => import('./Clients/TenantsContainer'));
const Notifications = React.lazy(() => import('./Notifications/Notifications'));
// const Modules = React.lazy(() => import('./Modules/Modules'));
const Client = React.lazy(() => import('./Client/Client'));
const User = React.lazy(() => import('./User/User'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const Roles = React.lazy(() => import('./RolePermissions/RolePermissions'));
const States = withPermission(PERMISSIONS.STATES.READ)(React.lazy(() => import('./States')));

export default [
  {
    path: '/',
    title: 'Dashboard',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/clients',
    title: 'Customers',
    component: Clients,
    exact: true,
  },
  {
    path: '/users',
    title: 'Users',
    component: Users,
    exact: true,
  },
  {
    path: '/notifications',
    title: 'Notifications',
    component: Notifications,
    exact: true,
  },
  // {
  //   path: '/modules',
  //   title: 'Modules',
  //   component: Modules,
  //   exact: true,
  // },
  {
    path: '/roles',
    title: 'Roles',
    component: Roles,
    exact: true,
  },
  {
    path: '/states',
    title: 'States',
    component: States,
    exact: true,
    hidden: true,
  },
  {
    path: '/clients/:id',
    title: 'Customer',
    component: Client,
    hidden: true,
  },
  {
    path: '/users/:id',
    title: 'User',
    component: User,
    hidden: true,
  },
  {
    path: '*',
    component: NotFound,
    hidden: true, // hide from navigation
  },
];
